<template>
  <v-app>
    <v-main>
      <v-container class="edit-style">
        <v-row justify="center" >
          <v-col cols="12" sm="6">
            <!-- 详情 -->
            <div>
              <v-form ref="project">
                <v-expansion-panels :value="expansion_index_a" flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="pa-4">
                      <span class="subtitle-1">项目信息</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12" class="d-flex align-center">
                          <span class="text-caption px-2"
                            ><span class="red--text">*</span>项目编号</span
                          >
                          <v-col cols="8" class="del-p">
                            <v-text-field
                              readonly
                              outlined
                              flat
                              v-model="post_data.project.projectSerial"
                              single-line
                              class="del-text-field-msg pa-0 text-caption"
                              label="请输入"
                              dense
                            >
                            </v-text-field>
                          </v-col>
                        </v-col>
                        <v-col cols="12" class="d-flex align-center del-pt">
                          <span class="text-caption px-2"
                            ><span class="red--text">*</span>工程名称</span
                          >
                          <v-col cols="8" class="del-p">
                            <v-text-field
                              readonly
                              outlined
                              flat
                              v-model="post_data.project.projectName"
                              single-line
                              class="del-text-field-msg pa-0 text-caption"
                              label="请输入"
                              dense
                            >
                            </v-text-field>
                          </v-col>
                        </v-col>
                        <v-col cols="12" class="d-flex align-center del-pt">
                          <span class="text-caption px-2"
                            ><span class="red--text">*</span>改造地点</span
                          >
                          <v-col cols="8" class="del-p">
                            <v-text-field
                              readonly
                              outlined
                              flat
                              v-model="post_data.project.location"
                              single-line
                              class="del-text-field-msg pa-0 text-caption"
                              label="请输入"
                              dense
                            >
                            </v-text-field>
                          </v-col>
                        </v-col>
                        <v-col cols="12" class="d-flex align-center del-pt">
                          <span class="text-caption px-2"
                            ><span class="red--text">*</span>改造内容</span
                          >
                          <v-col cols="8" class="del-p">
                            <v-textarea
                              readonly
                              outlined
                              flat
                              v-model="post_data.project.retrofitContent"
                              class="del-text-field-msg text-caption"
                            ></v-textarea>
                          </v-col>
                        </v-col>

                        <!-- 新增 -->
                        <v-col cols="12" class="d-flex align-center del-pt">
                          <span class="text-caption px-2"
                            ><span class="red--text">*</span>申请部门类型</span
                          >
                          <v-col cols="7" class="del-p">
                            <v-select
                              :label="post_data.project.deptType"
                              readonly
                              :item="post_data.project.deptType"
                              dense
                              solo
                              class="del-text-field-msg pa-0"
                              style="width: 100%"
                            ></v-select>
                          </v-col>
                        </v-col>
                        <v-col cols="12" class="d-flex align-center del-pt">
                          <span class="text-caption px-2"
                            ><span class="red--text">*</span>
                            <span style="letter-spacing: 2px"
                              >申请人部门</span
                            ></span
                          >
                          <v-col cols="7" class="del-p">
                            <v-select
                              :label="deptName"
                              readonly
                              :item="deptName"
                              dense
                              solo
                              class="del-text-field-msg pa-0"
                              style="width: 100%"
                            ></v-select>
                          </v-col>
                        </v-col><v-col cols="12" class="d-flex align-center">
                          <span class="text-caption px-2">
                            <span class="red--text">*</span>
                            <span v-html="applyNameHtml"></span>
                          </span>
                          <v-col cols="8" class="del-p">
                            <v-text-field
                              readonly
                              outlined
                              flat
                              v-model="post_data.project.applyName"
                              single-line
                              class="del-text-field-msg pa-0 text-caption"
                              label="请输入"
                              dense
                            >
                            </v-text-field>
                          </v-col>
                        </v-col>
                        <v-col cols="12" class="d-flex align-center">
                          <span class="text-caption px-2">
                            <span class="red--text">*</span>
                            申请人手机号
                          </span>
                          <v-col cols="8" class="del-p">
                            <v-text-field
                              readonly
                              outlined
                              flat
                              v-model="post_data.project.applyPhone"
                              single-line
                              class="del-text-field-msg pa-0 text-caption"
                              label="请输入"
                              dense
                            >
                            </v-text-field>
                          </v-col>
                        </v-col>

                        <v-col
                          cols="12"
                          class="d-flex align-center del-pt del-pb"
                        >
                          <span class="text-caption px-2"
                            ><span class="red--text">*</span>施工图</span
                          >
                        </v-col>

                        <v-col cols="12" class="del-pt">
                          <p
                            class="ma-2"
                            v-for="item in post_data.fileVos"
                            :key="item.url"
                          >
                            <span class="body-2">{{ item.fileName }}</span>
                            <v-icon
                              class="mx-4 cursor-p"
                              @click="_openAction(item)"
                              >mdi-file-eye-outline</v-icon
                            >
                          </p>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-expansion-panels
                  :value="expansion_index_b"
                  class="pt-4"
                  flat
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header class="pa-4">
                      <span class="subtitle-1">项目内容陈述</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      v-for="(item, i) in content_present_list"
                      :key="i"
                    >
                      <template
                        v-if="
                          action_type == 'detail' ||
                            (item.id == departmentId && action_type == 'edit')
                        "
                      >
                        <p class="subtitle-2 del-mb font-weight-bold">
                          {{ item.name }}
                        </p>
                        <div v-for="child in item.data" :key="child.id">
                          <div
                            v-if="child.checkOption"
                            class="d-flex justify-space-between align-center"
                          >
                            <span class="text-caption"
                              ><span
                                v-if="child.fillFlagCn == '是'"
                                class="red--text"
                                >*</span
                              >{{ child.contentPresent }}</span
                            >
                            <v-radio-group
                              readonly
                              v-model="child.checkOption"
                              row
                              class="del-text-field-msg pa-0 del-mt"
                            >
                              <v-radio
                                :click="(child.contentPresentId = child.id)"
                                :label="child.optionOne"
                                :value="'0'"
                              ></v-radio>
                              <v-radio
                                :click="(child.contentPresentId = child.id)"
                                :label="child.optionTwo"
                                :value="'1'"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                          <div class="pt-1" v-if="child.inputContent !== null">
                            <v-text-field
                              readonly
                              :rules="
                                child.checkOption == `${child.triggerCondition}`
                                  ? [rules.required]
                                  : false
                              "
                              v-model="child.inputContent"
                              :placeholder="child.presentation"
                              single-line
                              class="del-text-field-msg pa-0"
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                      </template>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-expansion-panels
                  :value="expansion_index_c"
                  class="pt-4"
                  flat
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header class="pa-4">
                      <span class="subtitle-1">单位信息</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col
                          cols="12"
                          class="d-flex align-center del-pt my-2"
                        >
                          <span class="text-caption px-2"
                            ><span class="red--text">*</span
                            ><span class="col-label-text">使用单位</span></span
                          >
                          <v-col cols="8" class="del-p">
                            <v-text-field
                              readonly
                              outlined
                              flat
                              v-model="post_data.project.userDept"
                              single-line
                              class="del-text-field-msg pa-0"
                              label="请输入"
                              dense
                            >
                            </v-text-field>
                          </v-col>
                        </v-col>
                        <v-col
                          cols="12"
                          class="d-flex align-center del-pt my-2"
                        >
                          <span class="text-caption px-2"
                            ><span class="red--text">*</span
                            ><span class="col-label-text"
                              >造价咨询单位</span
                            ></span
                          >
                          <v-col cols="8" class="del-p">
                            <v-text-field
                              readonly
                              outlined
                              flat
                              v-model="post_data.project.costConsultDept"
                              single-line
                              class="del-text-field-msg pa-0"
                              label="请输入"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-col>
                        <v-col
                          cols="12"
                          class="d-flex align-center del-pt my-2"
                        >
                          <span class="text-caption px-2"
                            ><span class="red--text">*</span
                            ><span class="col-label-text">设计单位</span></span
                          >
                          <v-col cols="8" class="del-p">
                            <v-text-field
                              readonly
                              outlined
                              flat
                              v-model="post_data.project.designDept"
                              single-line
                              class="del-text-field-msg pa-0"
                              label="请输入"
                              dense
                            >
                            </v-text-field>
                          </v-col>
                        </v-col>
                        <v-col
                          cols="12"
                          class="d-flex align-center del-pt my-2"
                        >
                          <span class="text-caption px-2"
                            ><span class="red--text">*</span
                            ><span class="col-label-text">监理单位</span></span
                          >
                          <v-col cols="8" class="del-p">
                            <v-text-field
                              readonly
                              outlined
                              flat
                              v-model="post_data.project.supervisionDept"
                              single-line
                              class="del-text-field-msg pa-0"
                              label="请输入"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-expansion-panels
                  flat
                  :value="expansion_index_d"
                  class="pt-4"
                  v-for="(item, index) in examine_records_desserts"
                  :key="item[index]?item[index].id:index+5645648"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header class="pa-4">
                      <span class="subtitle-1"
                        >会审记录<span class="red--text font-weight-bold ml-2"
                          >(第 {{ index + 1 }} 轮)</span
                        ></span
                      >
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <!-- <v-data-table hide-default-footer dense :headers="examine_records_headers" :items="examine_records_desserts[index]" item-key="id">
                  </v-data-table> -->

                      <v-list three-line>
                        <v-list-item
                          class="del-plr"
                          v-for="(list, i) in examine_records_desserts[index]"
                          :key="i"
                          ripple
                          @click="() => {}"
                        >
                          <v-sheet
                            color="blue"
                            class="
                              white--text
                              rounded-circle
                              d-flex
                              justify-center
                              align-center
                            "
                            elevation="0"
                            height="32"
                            width="36"
                            ><span>{{
                              list.deptName | substring
                            }}</span></v-sheet
                          >
                          <div class="w-100 ml-2 mt-2">
                            <div class="subtitle-2">
                              {{ list.deptName }}{{ `(${list.user})` }}
                            </div>
                            <div
                              class="
                                text-caption
                                d-flex
                                justify-space-between
                                flex-column
                                grey--text
                              "
                            >
                              <span>{{
                                list.meetingAuditTime
                                  ? `时间: ${list.meetingAuditTime}`
                                  : ""
                              }}</span>
                              <span
                                :class="[
                                  list.auditFlag == '不通过'
                                    ? 'red--text'
                                    : list.auditFlag == '通过'
                                    ? 'green--text'
                                    : '',
                                ]"
                              >
                                {{
                                  list.content
                                    ? `【${list.auditFlag}】${list.content}`
                                    : list.auditFlag
                                }}</span
                              >
                            </div>
                          </div>
                        </v-list-item>
                      </v-list>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-expansion-panels
                  flat
                  :value="expansion_index_e"
                  class="pt-4"
                  v-if="action_type == 'detail' ? false : true"
                >
                  <v-expansion-panel v-if="!isShow && !show">
                    <v-expansion-panel-header class="pa-4">
                      <span class="subtitle-1">会审批准</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="detail-table-style">
                        <v-col cols="12" class="d-flex align-center del-pt">
                          <span class="text-caption px-2"
                            ><span class="red--text">*</span
                            ><span class="col-label-text">会审意见</span></span
                          >
                          <v-col cols="8" class="del-p">
                            <v-radio-group
                              :rules="[rules.required]"
                              v-model="post_audit.auditFlag"
                              row
                              class="del-text-field-msg pa-0 del-mt"
                            >
                              <v-radio label="同意" :value="'0'"></v-radio>
                              <v-radio label="不同意" :value="'1'"></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-col>
                        <v-col cols="12" class="d-flex align-center del-pt">
                          <span class="text-caption px-2"
                            ><span class="red--text">*</span
                            ><span class="col-label-text">意见内容</span></span
                          >
                          <v-col cols="8" class="del-p">
                            <v-textarea
                              :rules="[rules.required]"
                              v-model="content"
                              class="del-text-field-msg"
                              outlined
                            ></v-textarea>
                          </v-col>
                        </v-col>
                        <v-col cols="12" class="d-flex align-center del-pt">
                          <span class="text-caption px-2"
                            ><span class="col-label-text">温馨提示</span></span
                          >
                          <v-col cols="8" class="del-p">
                            <span class="red--text">{{ review_tips }}</span>
                          </v-col>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel v-else>
                    <v-expansion-panel-header class="pa-4">
                      <span class="subtitle-1">意见栏</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="detail-table-style">
                        <v-col cols="12" class="d-flex align-center del-pt">
                          <span class="text-caption px-2"
                            ><span class="red--text">*</span
                            ><span class="col-label-text">意见内容</span></span
                          >
                          <v-col cols="8" class="del-p">
                            <v-textarea
                              v-model="content"
                              class="del-text-field-msg"
                              outlined
                            ></v-textarea>
                          </v-col>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-form>

              <v-row
                justify="center"
                class="pa-3 mt-1"
                v-if="action_type == 'detail' ? false : true"
              >
                <v-btn
                  class="py-5 rounded"
                  @click="back"
                  block
                  dark
                  small
                  tile
                  elevation="3"
                  v-if="isShow || show"
                  style="background: #eee; color: #333"
                  >退回</v-btn
                >
                <v-btn
                  class="py-5 rounded"
                  @click="_setProjectAction"
                  block
                  color="primary"
                  dark
                  small
                  tile
                  elevation="3"
                  style="margin-top: 10px"
                  >提交</v-btn
                >
              </v-row>
            </div>
          </v-col>
          <v-row justify="center" class="pa-3 mt-1" v-if="toggle_now == '0'">
            <v-dialog v-model="dialog" persistent max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on">
                  Open Dialog
                </v-btn> -->
                <v-btn
                  
                  @click="withdrawApprovalShow"
                  block
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
                  tile
                  elevation="3"
                  v-if="toggle_now == '0'"
                  style="background: #eee; color: #333;font-size:16px;margin-bottom:20px;height:40px;line-height: 20rpx"
                  >撤回审批</v-btn
                >
              </template>
              <v-card>
                <v-card-title class="text">
                  您确定要撤回本轮审批意见吗?
                </v-card-title>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" text @click="dialog = false">
                    取消
                  </v-btn>
                  <v-btn color="green darken-1" text @click="withdrawApproval">
                    确定
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import AppBar from '@/components/app-bar.vue';
import tools from "@/util/tools";
import {
  getContentPresentList,
  getProjectDetail,
  setProjectAudit,
  isInfrastructure,
  returnInfrastructure,
  withdraw,
} from "@/api/api";
// isInfrastructure
export default {
  props: ["scroll_data"],
  data() {
    return {
      applyNameHtml:'申&nbsp;&nbsp;&nbsp;&nbsp;请&nbsp;&nbsp;&nbsp;&nbsp;人&nbsp;',
      rules: {
        required: (value) => !!value || "必填",
      },
      search_data: {}, // 搜索数据
      search_date: [], // 搜索时间
      search_date_visible: false, // 选择时间组件
      toggle_index: 1, //视图nav id
      list_nav: [{ name: "已办事项" }, { name: "待办事项" }], // 视图nav
      headers: [
        { text: "操作", value: "action", sortable: false },
        { text: "编号", value: "project.projectSerial", sortable: false },
        { text: "工程名称", value: "project.projectName", sortable: false },
        { text: "改造地点", value: "project.location", sortable: false },
        { text: "申请人", value: "project.createUser", sortable: false },
        { text: "申请时间", value: "project.createTime", sortable: false },
        { text: "状态", value: "project.auditStatusCn", sortable: false },
        { text: "本轮会审情况", value: "currentExamine", sortable: false },
      ], // 列表头
      desserts: [], // 列表数据

      examine_records_headers: [
        { text: "部门", value: "deptName", sortable: false },
        { text: "会审人", value: "user", sortable: false },
        { text: "会审时间", value: "meetingAuditTime", sortable: false },
        { text: "会审意见", value: "auditFlag", sortable: false },
        { text: "意见内容", value: "content", sortable: false },
      ], // 会审记录 - 列表头
      examine_records_desserts: [], // 会审记录 - 列表数据

      selected_data: [], // 列表已选数据
      child_tab: {
        child_visible: true,
        child_title: "会审列表",
      }, // 视图
      content_present_list: [], // 陈述列表
      post_data: {
        project: {},
        projectPresents: [],
      }, // 提交数据
      post_audit: {},
      work_draw: [], // 施工图文件
      expansion_index_a: 0, // 陈述列表展开id
      expansion_index_b: "", // 陈述列表展开id
      expansion_index_c: "", // 陈述列表展开id
      expansion_index_d: "", // 陈述列表展开id
      expansion_index_e: "", // 陈述列表展开id
      action_type: "list", // 当前操作
      departmentId: tools.getUsetInfo().departmentId,
      dept_tips: [
        { id: "250", name: "基建办公室 ", text: "结构、承重及外立面情况" },
        { id: "238", name: "总务与空间办公室 ", text: "装修区域空间情况" },
        { id: "241", name: "设备设施维护办公室 ", text: "水电气、空调等情况" },
        { id: "242", name: "安全、健康与环境办公室 ", text: "消防设备情况" },
        { id: "260", name: "网络信息中心 ", text: "弱电情况" },
      ], // 会审提示
      review_tips: "",
      content: "", //意见
      opinionShow: false,
      isShow: {},
      show: 0,
      deptName: "",
      dialog: false,
    };
  },
  /* import引入的组件 */
  //   components: { AppBar },
  /* 监听属性 类似于data概念 */
  computed: {
    toggle_now() {
      return sessionStorage.getItem("toggle_index");
    },
  },
  /* 监控data中的数据变化 */
  watch: {},
  /* 方法集合 */
  methods: {
    withdrawApprovalShow() {
      this.dialog = true;
    },
    // 撤回审批
    withdrawApproval() {
      // this.dialog = true;
      // console.log(this.post_data.project.projectSerial)
      let projectSerial = this.post_data.project.projectSerial;
      // // console.log(item.project.projectSerial);

      withdraw({ projectSerial })
        .then((res) => {
          if (res.code == "0000") {
            this.$notify({
              title: "操作成功",
              type: "success",
            });
          }
          this.$router.replace({ path: "/" });
          sessionStorage.setItem("ok", true);

          //         // this._getProjectList();
          //         // sessionStorage.setItem("isEditNow", JSON.stringify([]));
        })
        .catch((err) => {
          this.$notify({
            title: "操作失败",
            message: err.msg,
            type: "error",
          });
          console.log(err);
        });
      this.dialog = false;
      //   })
      //   .catch(() => {
      //     console.log("取消撤回");
      //   });
    },
    // 退回
    back() {
      let data = {
        projectId: this.post_data.project.id,
        content: this.content,
      };
      // console.log(data);
      if (this.content == "") {
        this.$notify({
          title: "请填写退回意见！",
          type: "error",
        });
      } else {
        returnInfrastructure(data).then((res) => {
          if (res.code == "0000") {
            this.$notify({
              title: "操作成功",
              type: "success",
            });
            this.$router.replace({ path: "/" });
          }
        });
      }
    },
    // 预览文件
    _openAction(file) {
      let self = this;
      if (!file.url) {
        self.$notify({
          title: `无预览文件`,
          type: "error",
        });
      } else {
        window.open(file.url);
      }
    },

    /**
     *〈 内容陈述 -> 获取列表 〉
     */
    _getContentPresentList() {
      let self = this;
      let params = {
        currentPage: 1, // 当前页:默认值1
        pageSize: 999, // 每页条数:默认值10
      };
      getContentPresentList(params)
        .then((res) => {
          if (res.code == "0000") {
            let deptArray = [
              { name: "基建办公室", id: "250", data: [] },
              { name: "总务与空间办公室", id: "238", data: [] },
              { name: "设施设备维护办公室", id: "241", data: [] },
              { name: "安全、健康与环境办公室", id: "242", data: [] },
              { name: "网络信息中心", id: "260", data: [] },
            ];
            res.data.records.forEach((item) => {
              deptArray.forEach((dept) => {
                if (item.deptId == dept.id) {
                  dept.data.push(item);
                }
              });
            });
            self.content_present_list = deptArray;
          }
          // console.log(res.data);
        })
        .then(() => {
          self._getProjectDetail({ id: self.$route.query.project_id });
        })
        .catch((err) => {
          console.log(err);
          self.$notify({
            title: err.msg,
            type: "error",
          });
        });
    },

    /**
     *〈 会审批准 -> 详情 〉
     */
    _getProjectDetail({ id }) {
      let self = this;
      let params = { id: id };
      self.examine_records_desserts = [];
      self.post_audit = {};
      getProjectDetail(params)
        .then((res) => {
          if (res.code) {
            // console.log(res.data.project.id);
            self.detail_data = res.data;
            // console.log(item)
            isInfrastructure(res.data.project.id).then((res) => {
              if (res.code == "0000") {
                // console.log(res.data);
                this.isShow = res.data;
              } else {
                this.isShow = false;
              }
            });
            // console.log(self.detail_data2, 11111111111111);

            self.post_data.project = res.data.project;
            self.post_data.fileVos = res.data.fileVos;
            self.review_tips = res.data.tips;
            self.deptName = res.data.project.dwmc;
            res.data.projectPresents.forEach((item) => {
              self.content_present_list.forEach((list) => {
                if (list.data.length > 0) {
                  list.data.forEach((child) => {
                    if (item.contentPresentId == child.id) {
                      item.checkOption = item.checkOption.toString();
                      child = Object.assign(child, item);
                    }
                  });
                }
              });
            });
            for (
              let index = 0;
              index < Object.keys(res.data.examineRecords).length;
              index++
            ) {
              res.data.examineRecords[index + 1].forEach((item) => {
                // if (item.isInfrastructure && item.auditFlag != 10) {
                //   item.auditFlag = "-";
                // }
                switch (item.auditFlag) {
                  case 0:
                    item.auditFlag = "通过";
                    break;
                  case 1:
                    item.auditFlag = "不通过";
                    break;
                  case 2:
                    item.auditFlag = "未审批";
                    break;
                  case 5:
                    item.auditFlag = "审批人撤回";
                    break;
                  case 10:
                    item.auditFlag = "基建专员退回";
                    break;
                  case 11:
                    item.auditFlag = "待基建专员审批";
                    break;
                  case 12:
                    item.auditFlag = "-";
                    break;
                  case 15:
                    item.auditFlag = "申请人撤回";
                    break;
                  default:
                    break;
                }
              });
              self.examine_records_desserts.push(
                res.data.examineRecords[index + 1]
              );
              // console.log(self.examine_records_desserts)
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     *〈 会审批准 〉
     */
    _setProjectAction() {
      let self = this;
      let params = JSON.parse(JSON.stringify(self.post_audit));
      // console.log(params, self.post_audit);
      if (this.show) {
        // console.log(11111111111111111111);
        if (this.isShow) {
          // let self = this;
          // let params = JSON.parse(JSON.stringify(self.post_audit));
          //写意见
          params.projectId = self.post_data.project.id;
          params.auditFlag = 0;
          params.content = this.content;
          console.log(params, "==>");
          if (params.content == "") {
            this.$notify({
              title: "请填写提交意见！",
              type: "error",
            });
          } else {
            setProjectAudit(params)
              .then((res) => {
                if (res.code == "0000") {
                  self.$notify({
                    title: "操作成功",
                    type: "success",
                  });
                  self.$router.replace({ path: "/" });
                }
              })
              .catch((err) => {
                self.$notify({
                  title: "操作失败",
                  message: err.msg,
                  type: "error",
                });
                console.log(err);
              });
          }
        } else {
          this.$notify({
            title: `请等待审批完成后再填写意见！`,
            type: "error",
          });
        }
      } else {
        
        // console.log(22222222222222222222222);
        params.projectId = self.post_data.project.id;
        params.auditFlag=params.auditFlag == 1?1:0;
        params.content = this.content;
        if (!self.$refs.project.validate()) {
          self.$notify({
            title: `请检查必填项`,
            type: "error",
          });
          return;
        } else if (!params.content || params.auditFlag === undefined) {
          console.log(params, params.content, params.auditFlag);
          self.expansion_index_e = 0;
          self.$notify({
            title: `请完善意见内容`,
            type: "error",
          });
          return;
        }
        // 会审
        params.projectId = self.post_data.project.id;
        params.auditFlag = parseInt(params.auditFlag);
        setProjectAudit(params)
          .then((res) => {
            if (res.code == "0000") {
              self.$notify({
                title: "操作成功",
                type: "success",
              });
              self.$router.replace({ path: "/" });
            }
          })
          .catch((err) => {
            self.$notify({
              title: "操作失败",
              message: err.msg,
              type: "error",
            });
            console.log(err);
          });
      }
    },
  },
  filters: {
    substring: function(value) {
      return value.substring(0, 1);
    },
  },
  /* 生命周期 - 创建完成（可以访问当前this实例） */
  created() {
    let self = this;
    self.action_type = self.$route.query.type;
    self._getContentPresentList();
    document.title = "施工图会审系统 - 会审详情";
  },
  /* 生命周期 - 挂载完成（可以访问DOM元素） */
  mounted() {
    let flag = JSON.parse(sessionStorage.getItem("flag"));
    console.log(flag);
    // console.log(this.scroll_data)
    let id = sessionStorage.getItem("projectId");
    flag.forEach((item) => {
      if (item.id == id) {
        this.show = item.show;
      }
    });
  },
  /* 生命周期 - 创建之前 */
  beforeCreate() {},
  /* 生命周期 - 挂载之前 */
  beforeMount() {},
  /* 生命周期 - 更新之前 */
  beforeUpdate() {},
  /* 生命周期 - 更新之后 */
  updated() {},
  /* 生命周期 - 销毁之前 */
  beforeDestroy() {},
  /* 生命周期 - 销毁完成 */
  destroyed() {},
  /* 如果页面有keep-alive缓存功能，这个函数会触发 */
  activated() {},
};
</script>
<style lang="scss">
.edit-style {
  .v-expansion-panel-content__wrap {
    padding: 16px !important;
    padding-top: 0 !important;
  }
  .v-list--three-line .v-list-item,
  .v-list-item--three-line {
    min-height: 55px !important;
  }
}
.v-btn__content{
  line-height: 20rpx !important;
}
</style>
<style lang="scss" scoped></style>
